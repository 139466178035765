import { Typography } from 'antd';
import React from 'react';
import AccountMenu from '../../components/account/AccountMenu';
import MobileMenu from '../../components/MobileMenu';

const { Text } = Typography;

const AccountMenuPage = () => {
  return (
    <div className="flex flex-col h-[calc(var(--vh,1vh)*100)]">
      <div className="px-6 py-4 border-b border-light-divider">
        <Text className="!text-base !font-semibold !text-light-title">
          บัญชี
        </Text>
      </div>
      <div className="flex-1 overflow-auto px-4">
        <AccountMenu />
      </div>

      <MobileMenu />
    </div>
  );
};

export default AccountMenuPage;
