import { Link, navigate } from 'gatsby';
import React, { useState } from 'react';
import tailwindConfig from '../../../tailwind.config';
import AccountIcon from '../../icons/account';
import DocumentIcon from '../../icons/document';
import LogoutIcon from '../../icons/logout';
import { useCustomer } from '../../hooks/use-customer';

import { ORDERS_PATH, PROFILE_PATH } from './AccountLayout';

const resolveConfig = require('tailwindcss/resolveConfig');
const config = resolveConfig(tailwindConfig);

const lightTitleColor = config.theme.colors.light.title;

const activeColor = config.theme.colors.primary[6];

const AccountMenu = () => {
  const {
    actions: { logoutCustomer },
  } = useCustomer();
  const [hover, setHover] = useState('');

  return (
    <div className="flex flex-col w-full">
      <div className="md:px-6 py-4 border-b font-semibold border-light-divider">
        <Link
          className={'flex items-center !font-normal mb-[26px] md:mb-[18px]'}
          to={PROFILE_PATH}
          onMouseOver={() => setHover('my-account')}
          onMouseLeave={() => setHover('')}
        >
          <AccountIcon
            color={hover === 'my-account' ? activeColor : lightTitleColor}
          />
          <div className="ml-1 text-light-title hover:text-primary-6">
            บัญชีของฉัน
          </div>
        </Link>
        <Link
          className={'flex items-center !font-normal'}
          to={ORDERS_PATH}
          onMouseOver={() => setHover('orders')}
          onMouseLeave={() => setHover('')}
        >
          <DocumentIcon
            color={hover === 'orders' ? activeColor : lightTitleColor}
          />{' '}
          <div className="ml-1 text-light-title hover:text-primary-6">
            ประวัติการสั่งซื้อ
          </div>
        </Link>
      </div>
      <div
        className={'flex items-center md:px-6 !font-normal py-4 cursor-pointer'}
        onMouseEnter={() => setHover('signout')}
        onMouseLeave={() => setHover('')}
        onClick={async (e) => {
          e.stopPropagation();
          e.preventDefault();
          logoutCustomer();
          navigate('/sign-in');
        }}
      >
        <LogoutIcon
          color={hover === 'signout' ? activeColor : lightTitleColor}
        />
        <div className="ml-1 text-light-title hover:text-primary-6">
          ออกจากระบบ
        </div>
      </div>
    </div>
  );
};

export default AccountMenu;
